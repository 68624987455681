.main__services-one {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}
.main__service-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 800;
  color: darkblue;
  letter-spacing: 5px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.main__services-item {
  padding: 15px;
  background-color: #003253;
  transition: 0.5s;
}
.main__services-item:hover {
  transform: scale(1.05);
  transition: 0.5s;
  background-color: #003253;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
}
.main__services-img-price {
  display: flex;
  justify-content: space-between;
}
.main__services-price {
  display: flex;
  align-self: center;
  color: #fff;
  font-size: 25px;
}
.main__services-name-link {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.main__services-name {
  display: flex;
  align-self: center;
  color: #fff;
  font-size: 25px;
  text-transform: uppercase;
  text-align: left;
}
.service_icon {
  width: 50px;
  height: 40px;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}
.car-clean-img {
  margin: 0 auto;
}
.car-clean-name {
  text-align: center;
}
/* about servise */
.about-service_img_text {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.about-service_img {
  border-radius: 15px;
  width: 50%;
}
.about-service_text {
  width: 45%;
}
.about-service_text h2 {
  text-align: center;
  padding: 15px;
}
.about-service_text p {
  padding: 10px;
  font-size: 20px;
  text-align: justify;
}
.about-servise_link {
  margin: 10px;
  max-width: 200px;
  padding: 15px;
  background-color: rgb(67, 67, 255);
  color: #fff;
  text-align: center;
  font-size: 20px;
  transition: 0.5s;
}
.about-servise_link:hover {
  background-color: transparent;
  color: blue;
  text-align: center;
  font-size: 20px;
  transition: 0.5s;
  outline: 1px solid blue;
}
@media (max-width: 900px) {
  .about-service_text h2 {
    text-align: center;
    padding: 15px;
    font-size: 18px;
  }
  .about-service_text p {
    padding: 10px;
    font-size: 16px;
    text-align: justify;
  }
  .main__services-one {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}
@media (max-width: 600px) {
  .about-service_img_text {
    display: flex;
    flex-direction: column;
  }
  .about-service_img {
    border-radius: 15px;
    width: 90%;
  }
  .about-service_text {
    width: 90%;
  }
  .main__services-one {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .main__services-item {
    width: 90%;
    padding: 15px;
    background-color: #003253;
    transition: 0.5s;
    margin: 0 auto;
  }
  .main__services-item:hover {
    transform: scale(1.05);
    transition: 0.5s;
    background-color: #003253;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
  }
}
@media (max-width: 350px) {
  .main__service-title {
    font-size: 25px;
  }
  .service_icon {
    width: 40px;
    height: 30px;
  }
  .main__services-name {
    display: flex;
    align-self: center;
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    text-align: left;
  }
  .main__services-img img {
    transform: scale(0.8);
  }
  .main__services-name-link {
    margin-top: 20px;
  }
  .about-service_text h2 {
    text-align: center;
    padding: 5px;
    font-size: 15px;
  }
  .about-service_text p {
    padding: 5px;
    font-size: 14px;
    text-align: justify;
  }
}
