.article {
  padding-bottom: 30px;
}
.article-title {
  text-align: center;
  text-transform: uppercase;
  color: darkblue;
  margin: 30px auto;
  font-weight: 800;
  font-size: 30px;
  padding: 15px;
}
@media (max-width: 500px) {
  .article-title {
    margin-top: 0px;
    font-size: 25px;
  }
}
