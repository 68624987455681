/* .text {
  position: absolute;
  top: 40%;
  left: 2%;
  max-width: 700px;
  padding: 60px 40px;
  background-color: #fff;
  opacity: 0.8;
  border-radius: 15px;
  text-align: center;
} */
.text {
  margin-top: 100px;
  max-width: 700px;
  padding: 60px 40px;
  background-color: #fff;
  opacity: 0.8;
  border-radius: 15px;
  text-align: left;
}
.text_span {
  opacity: 1;
  display: block;
  font-size: 35px;
  text-transform: uppercase;
  color: #000;
  /* padding-bottom: 20px; */
  font-weight: 800;
  text-align: center;
}
.text_span2 {
  opacity: 1;
  display: inline-block;
  font-size: 30px;
  text-transform: uppercase;
  color: darkred;
  font-weight: 600;
  padding-bottom: 20px;
}
.text_span3 {
  opacity: 1;
  display: inline-block;
  font-size: 30px;
  text-transform: uppercase;
  color: darkred;
  font-weight: 600;
  padding-bottom: 20px;
}
.text_span4 {
  opacity: 1;
  display: inline-block;
  font-size: 30px;
  text-transform: uppercase;
  color: darkred;
  font-weight: 600;
}
.diagnostics {
  margin-right: 10px;
}
@media (max-width: 1100px) {
  /* .text {
    position: absolute;
    top: 45%;
    left: 2%;
    right: 2%;
    max-width: 700px;
    padding: 60px 20px;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 15px;
    text-align: center;
  } */

  .text_span {
    opacity: 1;
    display: inline-block;
    font-size: 35px;
    text-transform: uppercase;
    color: #000;
    padding-bottom: 20px;
    font-weight: 800;
  }
  .text_span2 {
    opacity: 1;
    display: inline-block;
    font-size: 30px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .text_span3 {
    opacity: 1;
    display: inline-block;
    font-size: 30px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .text_span4 {
    opacity: 1;
    display: inline-block;
    font-size: 30px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
  }
  .diagnostics {
    margin-right: 10px;
  }
}
@media (max-width: 600px) {
  .text {
    max-width: 700px;
    padding: 40px 10px;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 15px;
    text-align: center;
  }
  /* .text {
    position: absolute;
    top: 50%;
    left: 2%;
    right: 2%;
    max-width: 700px;
    padding: 40px 10px;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 15px;
    text-align: center;
  } */
  .text_span {
    opacity: 1;
    display: inline-block;
    font-size: 25px;
    text-transform: uppercase;
    color: #000;
    padding-bottom: 20px;
    font-weight: 800;
  }
  .text_span2 {
    opacity: 1;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .text_span3 {
    opacity: 1;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .text_span4 {
    opacity: 1;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
  }
  .diagnostics {
    margin-right: 10px;
  }
}
@media (max-width: 500px) {
  /* .text {
    position: absolute;
    top: 40%;
    left: 2%;
    right: 2%;
    max-width: 700px;
    padding: 40px 10px;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 15px;
    text-align: center;
  } */
  .text {
    margin-top: 30px;
    max-width: 700px;
    padding: 30px 10px;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 15px;
    text-align: center;
  }
  .text_span {
    opacity: 1;
    display: inline-block;
    font-size: 25px;
    text-transform: uppercase;
    color: #000;
    padding-bottom: 20px;
    font-weight: 800;
  }
  .text_span2 {
    opacity: 1;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .text_span3 {
    opacity: 1;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .text_span4 {
    opacity: 1;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
  }
  .diagnostics {
    margin-right: 10px;
  }
}
@media (max-width: 400px) {
  .text_span {
    opacity: 1;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    color: #000;
    padding-bottom: 20px;
    font-weight: 800;
  }
  .text_span2 {
    opacity: 1;
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .text_span3 {
    opacity: 1;
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 20px;
  }
  .text_span4 {
    opacity: 1;
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
  }
}
@media (max-width: 350px) {
  .text_h1 img {
    scale: 0.8;
  }
  .text_span {
    opacity: 1;
    display: inline-block;
    font-size: 20px;
    text-transform: uppercase;
    color: #000;
    padding-bottom: 10px;
    font-weight: 800;
  }
  .text_span2 {
    opacity: 1;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 10px;
  }
  .text_span3 {
    opacity: 1;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
    padding-bottom: 10px;
  }
  .text_span4 {
    opacity: 1;
    display: inline-block;
    font-size: 14px;
    text-transform: uppercase;
    color: darkred;
    font-weight: 600;
  }
}
