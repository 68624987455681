.main {
  padding-bottom: 45px;
  /* background-image: url(../../../img/fon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed; */
  background-color: #fff;
}
@media (max-width: 550px) {
  .main {
    padding-bottom: 25px;
  }
}
