/* forma */
.closeform {
  margin-top: 20px;
  cursor: pointer;
  position: absolute;
  right: 5%;
}
.closeform span {
  display: block;
  width: 30px;
  height: 5px;
  background-color: white;
}
.closeform span:nth-child(2) {
  transform: translateY(0) rotate(45deg);
}
.closeform span:nth-child(1) {
  transform: translateY(5px) rotate(-45deg);
}
.closeform:hover {
  animation-name: rotat;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-fill-mode: none;
  animation-iteration-count: 1;
}
@keyframes rotat {
  to {
    transform: translateY(0) rotate(0deg);
  }
  from {
    transform: translateY(0) rotate(180deg);
  }
}

.msgwtv {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.socialmsg2 {
  font-size: 18px;
  padding: 5px 0;
  color: #fff;
}
form {
  display: block;
  max-width: 500px;
  background-color: #264caa;
  margin: 5% auto;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
  z-index: 100;
  text-align: center;
}
.forma {
  display: block;
  max-width: 800px;
  background-color: #264caa;
  margin: 5% auto;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
  z-index: 0;
}
form h2 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 800;
  max-width: 350px;
  margin: 40px auto;
  text-align: center;
}
.forma-service-h2 {
  text-transform: uppercase;
  max-width: 400px;
}
input,
.btn {
  display: block;
  background-color: #ffffff;
  width: 90%;
  height: 60px;
  margin: 0 auto;
}
input {
  margin-bottom: 35px;
  padding-left: 17px;
  font-size: 14px;
  border-radius: 5px;
}
input:focus {
  border: 2px solid #00a6ff;
  font-size: 18px;
}
.form__btn {
  padding-bottom: 20px;
}
.btn {
  max-width: 200px;
  text-align: center;
  background-color: #fed133;
  color: #000;
  font-weight: 800;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
}
.personaldata {
  color: white;
  max-width: 400px;
  margin: 0 auto;
}
.socialmsg {
  color: white;
  font-size: 20px;
}
@media (max-width: 600px) {
  .forma {
    display: block;
    max-width: 400px;
    background-color: #264caa;
  }
  form {
    display: block;
    max-width: 400px;
    margin: 20% auto;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    position: relative;
    z-index: 100;
  }
}
@media (max-width: 400px) {
  .forma {
    display: block;
    max-width: 350px;
    background-color: #264caa;
  }
  form {
    display: block;
    max-width: 350px;
    margin: 20% auto;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 20px;
    position: relative;
    z-index: 100;
  }
  .socialmsg2 {
    font-size: 16px;
  }
}
