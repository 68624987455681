.email__link {
  margin-top: 2px;
  font-weight: 600;
  padding-top: 0px;
}
.footer__email {
  font-size: 27px;
}
.email {
  display: inline-block;
  border-radius: 15px;
  color: #fff;
}
.email:hover {
  color: #f0efef;
}
.email_img {
  width: 28px;
  height: 28px;
  background-color: rgb(5, 60, 123);
  border-radius: 20%;
}
@media (max-width: 500px) {
  .email_img {
    width: 22px;
    height: 22px;
    background-color: rgb(5, 60, 123);
    border-radius: 20%;
  }
  .email__link {
    font-weight: 600;
    padding-top: 0px;
  }
}
@media (max-width: 400px) {
}
