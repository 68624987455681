.about {
  padding-bottom: 35px;
}
.about-title {
  padding: 15px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.about-title h1 {
  text-align: center;
  color: rgb(21, 4, 62);
  text-transform: uppercase;
  font-weight: 800;
  padding: 20px 0;
}
.about__content-img {
  max-width: 500px;
  margin: 0 auto;
}
.about__content-img img {
  width: 100%;
  border-radius: 10px;
}
.about__content-text {
  margin: 35px auto 0 auto;
  padding: 25px 15px;
  max-width: 1240px;
  border-radius: 10px;
  border: 1px solid rgb(101, 101, 255);
}
.about__content-text p {
  width: 90%;
  padding: 10px 10px;
  margin: 0 auto;
  text-align: justify;
  font-size: 18px;
  color: rgb(21, 4, 62);
}
.zasady-h2 {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  color: rgb(21, 4, 62);
  padding: 30px 0;
}
.zasady__list {
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  gap: 0px;
}
.zasay__list-item {
  border: 1px solid rgb(101, 101, 255);
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 10px;
}
.zasay__list-item h3 {
  text-transform: uppercase;
}
.zasay__list-item p {
  text-align: justify;
}
@media (max-width: 900px) {
  .about__content-img {
    max-width: 400px;
    margin: 0 auto;
  }
  .zasady__list {
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
    gap: 0px;
  }
}
@media (max-width: 700px) {
  .about__content-img {
    max-width: 350px;
    margin: 0 auto;
  }
  .zasady__list {
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto;
    gap: 0px;
  }
}
@media (max-width: 500px) {
  .about__content-text p {
    width: 90%;
    padding: 0;
    padding-top: 10px;
    margin: 0 auto;
    text-align: justify;
    font-size: 15px;
    color: rgb(21, 4, 62);
  }
  .about__content-img img {
    display: block;
    width: 80%;
    border-radius: 10px;
    margin: 20px auto;
  }
}
@media (max-width: 350px) {
  .zasay__list-item h3 {
    font-size: 14px;
  }
  .zasay__list-item p {
    font-size: 12px;
  }
}
