.footer {
  background-color: rgb(40, 36, 31);
  height: auto;
  padding: 35px 0 5px 0;
}
.footer__main {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.footer__menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.footer__menu h3 {
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer__menu__list {
  display: block;
  list-style-type: none;
}
.footer__menu__link {
  display: inherit;
  color: #ffffff;
  padding: 15px 0px;
  font-size: 20px;
}
.footer__menu__link:hover {
  color: rgb(91, 118, 163);
}
.footer__phone-social-email {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}
.footer__down {
  border-top: 1px solid #ccc;
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  color: #fff;
}
@media (max-width: 600px) {
  .footer__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  .footer__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
}
@media (max-width: 330px) {
  .footer__down {
    font-size: 12px;
    color: #fff;
  }
}
