.zayavka {
  padding: 10px 25px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  background-color: darkred;
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 15px;
  animation-name: zayavka;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  animation-fill-mode: both;
  cursor: pointer;
}
.showForma {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1000px;
  backdrop-filter: blur(4px);
  z-index: 100;
}
.zayavka:hover {
  border: 2px solid transparent;
  transition: all 0.5s;
  background-color: azure;
  color: black;
  animation: zayavka none;
}
.zayavka:active {
  background-color: rgb(255, 202, 202);
}
@keyframes zayavka {
  from {
    border: 2px solid rgba(173, 216, 230, 0.7);
  }

  to {
    border: 2px solid rgba(173, 216, 230, 1);
  }
}
@media (max-width: 600px) {
  .zayavka {
    padding: 8px 10px;
    text-align: center;
    font-size: 18px;
  }
}
@media (max-width: 450px) {
  .zayavka {
    padding: 11px 8px 0px 8px;
    font-size: 16px;
  }
}
@media (max-width: 370px) {
  .zayavka {
    padding: 5px;
    padding-top: 10px;
    font-size: 12px;
  }
}
@media (max-width: 300px) {
  .zayavka {
    padding: 0px;
    padding-top: 10px;
    width: 100%;
  }
}
@media (max-width: 260px) {
  .zayavka {
    padding: 0px;
    padding-top: 5px;
    width: 100%;
  }
}
