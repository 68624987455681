.header__social-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.facebook,
.instagram {
  scale: 1.5;
  background-color: rgb(5, 60, 123);
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.facebook:hover,
.instagram:hover {
  color: #ccc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.footer__social {
  display: flex;
  justify-content: center;
  gap: 30px;
  align-self: center;
}
@media (max-width: 500px) {
  .header__social-list {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .facebook,
  .instagram {
    scale: 1.2;
    background-color: rgb(5, 60, 123);
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}
@media (max-width: 400px) {
}
