.changeoil h1 {
  color: darkblue;
  text-align: center;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: 800;
}
.changeoil-h2 {
  color: darkblue;
  text-align: center;
  text-transform: uppercase;
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: 800;
}
.changeoil__img-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.changeoil-img {
  width: 42%;
  border-radius: 15px;
}
.changeoil-text {
  font-size: 20px;
  padding-left: 25px;
  width: 45%;
  text-align: justify;
}
.changeoil-text:nth-child(2) p {
  padding-top: 10px;
  padding-bottom: 10px;
}
.changeoil-zayavka {
  margin-top: 20px;
}
.changeoil-link {
  margin: 10px;
  padding: 15px;
  background-color: rgb(67, 67, 255);
  color: #fff;
  text-align: center;
  font-size: 20px;
  transition: all 0.5s;
}
.changeoil-link:hover {
  background-color: transparent;
  color: blue;
  text-align: center;
  font-size: 20px;
  transition: all 0.5s;
  outline: 1px solid blue;
}

.changeoil__services {
  padding: 15px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin: 10px;
}
.changeoil__item-title {
  padding: 20px 0 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 20px;
}
.changeoil__item-title h3 {
  text-transform: uppercase;
}
.changeoil__item {
  padding: 15px;
  border: 1px solid rgb(101, 101, 255);
}
.changeoil__item p {
  font-size: 18px;
  text-align: justify;
}
.formazayavka {
  margin: 10px;
}
.changefiltr {
  font-size: 20px;
}
.changefiltr li {
  max-width: 200px;
  margin: 0 auto;
  padding: 15px 0;
  font-style: italic;
}
@media (max-width: 900px) {
  .changeoil__img-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .changeoil-img {
    width: 80%;
    border-radius: 15px;
    margin-bottom: 10px;
  }
  .changeoil-text {
    font-size: 20px;
    padding-left: 0px;
    width: 90%;
    text-align: justify;
  }
  .changeoil__services {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 10px;
  }
}
@media (max-width: 600px) {
  .changeoil__services {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin: 10px;
  }
}
@media (max-width: 400px) {
  .changeoil h1 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 20px;
  }
  .changeoil-h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: 800;
    font-size: 19px;
  }
  .changeoil-text {
    font-size: 16px;
  }
  .changeoil__item-title h3 {
    font-size: 15px;
    width: 70%;
  }
  .changeoil__item p {
    font-size: 14px;
    text-align: justify;
  }
  .changeoil__services {
    padding: 5px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin: 5px;
  }
}
@media (max-width: 320px) {
  .changeoil-link {
    font-size: 16px;
  }
  .changeoil-link:hover {
    font-size: 16px;
  }
}
