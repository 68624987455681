.header__fon {
  position: relative;
  width: 100vw;
  /* height: 100vh; */
  background-image: url('../../../img/fon.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 35px;
}
.header__fon-scroll {
  position: relative;
  width: 100vw;
  /* height: 100vh; */
  background-image: url('../../../img/fon.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 35px;
  padding-top: 100px;
}
.container {
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 15px;
}
.header__contacts {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 35px;
  padding-bottom: 25px;
}
.header__contacts-scroll {
  opacity: 1;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: rgb(0, 181, 209);
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  transition: all 0.3s;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.1);
}
.header__menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__location {
  margin-right: auto;
}
.header__menu-zyavka-burger {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.social-email {
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
.social-email-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 600px) {
  .header__fon {
    position: relative;
    width: 100vw;
    /* height: 85vh; */
  }
}
@media (max-width: 500px) {
  .social-email-phone {
    display: block;
  }
  .header__fon {
    position: relative;
    width: 100vw;
    /* height: 70vh; */
  }
  .header__contacts {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    padding-top: 35px;
    padding-bottom: 10px;
  }
}
