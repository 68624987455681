.price {
  /* background-image: url(../../../img/fon.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed; */
  padding-bottom: 35px;
}
.outsidewidthtable table {
  background-color: azure;
}
.font-upper {
  background-color: white;
  /* text-transform: uppercase; */
}
.price-title {
  padding: 15px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}
.price-title h1 {
  text-align: center;
  color: rgb(21, 4, 62);
}
.price__services {
  margin: 20px;
  margin-bottom: 40px;
}
