.location_img {
  color: brown;
  font-size: 25px;
}
.location span {
  color: brown;
  font-size: 16px;
}
@media (max-width: 310px) {
  .location span {
    font-size: 12px;
  }
}
