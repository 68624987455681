.logo {
  display: block;
  width: 100px;
  height: 100px;
}
.logo__img {
  transform: scale(1.2);
  border-radius: 20% 0px 20% 0px;
  /* border-radius: 50%; */
}
@media (max-width: 480px) {
  .logo__img {
    transform: scale(1);
  }
}
@media (max-width: 430px) {
  .logo__img {
    transform: scale(0.9);
  }
}
@media (max-width: 300px) {
  .logo__img {
    transform: scale(0.7);
  }
}
