.header__burger-btn {
  display: none;
  position: relative;
  top: 0;
  right: 10px;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 2;
  background-color: transparent;
}
@media screen and (max-width: 960px) {
  .header__burger-btn {
    display: block;
  }
  .header__burger-item {
    position: absolute;
    width: 35px;
    height: 5px;
    background-color: #fff;
    display: block;
    left: 5px;
    transition: background-color 0.3s, opacity 0.3s, transform 0.5s;
  }
  .header__burger-item:nth-child(1) {
    transform: translateY(-12px);
  }
  .header__burger-item:nth-child(3) {
    transform: translateY(12px);
  }
  .open .header__burger-item:nth-child(1) {
    transform: translateY(0) rotate(45deg);
    background-color: white;
  }
  .open .header__burger-item:nth-child(2) {
    opacity: 0;
  }
  .open .header__burger-item:nth-child(3) {
    transform: translateY(0) rotate(-45deg);
    background-color: white;
  }

  .header__burger-btn:hover .header__burger-item {
    background-color: rgb(228, 222, 222);
  }
}
